import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import PageHeader from "../components/pageHeader";
import pageStyles from "./page.module.less";
import legalStyles from "./legal.module.less";
import SEO from "../components/SEO";

export default (props) => (
  <Layout>
  <SEO
      title="Privacy Policy"
    />
    <PageHeader
      header="Privacy Policy"
      subHeader="Take a look at our privacy policy."
    />
    <Container>
      <Row className={`justify-content-md-center ${pageStyles.pageParentRow} ${legalStyles.pageRow}`}>
        <Col lg={10} md={12}>
        {props.data.allMarkdownRemark.edges.map(edge => (
                <div className={legalStyles.markdownContent} dangerouslySetInnerHTML={{ __html: edge.node.html }} />
            ))}
        </Col>
      </Row>
    </Container>
  </Layout>
);

export const query = graphql`
  query LegalQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___title] }
      filter: {
        frontmatter: { title: { in: ["Privacy Policy"] } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          html
        }
      }
    }
  }
`;
